import store from '../redux/store';
import { isValidUrl } from './valid-url';
import { isIp, isValidCSVDate } from './validator';

export type PageTypeGroupingCSVHeaders = [
    'category',
    'content_length',
    'content_type',
    'geography',
    'homepage',
    'language',
    'market',
    'topic',
    'url'
];

type PageTypeGroupingSegment = {
    category: string;
    content_length: string;
    content_type: string;
    geography: string;
    homepage: string;
    language: string;
    market: string;
    topic: string;
    url: string;
};

export type OfflineSalesHeaders = [
    'account_token',
    'cubed_event',
    'currency',
    'customer_id',
    'event_date_time',
    'event_item_sku',
    'ip',
    'page_url',
    'referrer',
    'revenue',
    'sync_id_1',
    'sync_id_2',
    'sync_id_3',
    'transaction_id'
];

export type OfflineSalesData = {
    account_token: string;
    cubed_event: string;
    currency: string;
    customer_id: string;
    event_date_time: string;
    event_item_sku: string;
    ip: string;
    page_url: string;
    referrer: string;
    revenue: string;
    sync_id_1: string;
    sync_id_2: string;
    sync_id_3: string;
    transaction_id: string;
};

type ValidatePageTypeGroupingCsvFileResponse = {
    isValidated: boolean;
    fileValidationMessage: string;
};

export const validatePageTypeGroupingCsvFile = (
    headerData: PageTypeGroupingCSVHeaders,
    data: PageTypeGroupingSegment[],
    exportedUrlSet: Set<string>
): ValidatePageTypeGroupingCsvFileResponse => {
    const csvData = data;
    const sortedHeaders = [...headerData].sort();

    let errorMessage = '';
    let continueErrorCheck = true;

    for (let i = 0; i < sortedHeaders.length && continueErrorCheck; i++) {
        const header = sortedHeaders[i];

        for (let j = 0; j < csvData.length && continueErrorCheck; j++) {
            const rowData: PageTypeGroupingSegment = csvData[j];
            let isError = false;

            if (rowData[header] === undefined) {
                continueErrorCheck = false;
                isError = true;
                errorMessage = `Field is empty for ${header} column.`;
            } else if (header === 'url') {
                const url = rowData[header];

                if (!exportedUrlSet.has(url)) {
                    if (url === '') {
                        continueErrorCheck = false;
                        isError = true;
                        errorMessage = `The URL is empty`;
                    } else if (!isValidUrl(url)) {
                        continueErrorCheck = false;
                        isError = true;
                        errorMessage = `The URL, '${url}' you provided is not valid - it is either newly added or recently updated.`;
                    } else if (!rowData['market']) {
                        continueErrorCheck = false;
                        isError = true;
                        errorMessage = `The URL, '${url}' you provided is missing a required market value`;
                    }
                }
            }

            if (isError) {
                break;
            }
        }

        if (!continueErrorCheck) {
            break;
        }
    }

    if (errorMessage.length > 0) {
        return {
            isValidated: false,
            fileValidationMessage: `The file validation failed: ${errorMessage}.`,
        };
    }

    return {
        isValidated: true,
        fileValidationMessage: '',
    };
};
export const validateOfflineSalesCSVFile = (
    headersData: OfflineSalesHeaders,
    data: OfflineSalesData[],
    eventsData: Set<string>
): ValidatePageTypeGroupingCsvFileResponse => {
    const csvData = data;
    const accountToken = store.getState().account.token;

    const nonEmptyColumn = new Set(['account_token', 'cubed_event', 'event_date', 'page_url', 'referrer']);
    const validCurrencies = new Set([
        'ALL',
        'AUD',
        'BAM',
        'BGN',
        'BRL',
        'CAD',
        'CHF',
        'CNY',
        'CZK',
        'DKK',
        'EUR',
        'GBP',
        'GIP',
        'HKD',
        'HRK',
        'HUF',
        'INR',
        'JPY',
        'KRW',
        'NOK',
        'NZD',
        'PLN',
        'RON',
        'RSD',
        'RUB',
        'SEK',
        'SGD',
        'UAH',
        'USD',
        'ZAR',
    ]);
    const sortedHeaders = [...headersData].sort();

    let errorMessage = '';
    let continueErrorCheck = true;

    if (csvData.length === 0) {
        return {
            isValidated: false,
            fileValidationMessage: `The file validation failed: File is empty.`,
        };
    }

    for (let i = 0; i < sortedHeaders.length && continueErrorCheck; i++) {
        const header = sortedHeaders[i] as keyof OfflineSalesData;

        for (let j = 0; j < csvData.length && continueErrorCheck; j++) {
            const rowDataValue = csvData[j][header];

            if (nonEmptyColumn.has(header) && rowDataValue === '') {
                return {
                    isValidated: false,
                    fileValidationMessage: `The file validation failed: Field is empty for ${header} column which is a mandatory column.`,
                };
            }

            if (rowDataValue === undefined) {
                return {
                    isValidated: false,
                    fileValidationMessage: `The file validation failed. Field is empty for ${header} column.`,
                };
            }

            if (header === 'page_url' && !isValidUrl(rowDataValue)) {
                return {
                    isValidated: false,
                    fileValidationMessage: `The file validation failed: The page url ${rowDataValue} is not valid.`,
                };
            } else if (header === 'event_date_time' && !isValidCSVDate(rowDataValue)) {
                return {
                    isValidated: false,
                    fileValidationMessage: `The file validation failed: The Event Date, ${rowDataValue} you provided is not a valid date time in format YYYY-MM-DD hh:mm:ss.`,
                };
            } else if (header === 'cubed_event' && !eventsData.has(rowDataValue)) {
                return {
                    isValidated: false,
                    fileValidationMessage: `The file validation failed: The cubed event ${rowDataValue} is not valid.`,
                };
            } else if (header === 'account_token' && accountToken !== rowDataValue) {
                return {
                    isValidated: false,
                    fileValidationMessage: `The file validation failed: Found a account token ${rowDataValue} that is not valid for the account you have selected.`,
                };
            }

            if (rowDataValue === '') {
                continue;
            }

            switch (header) {
                case 'revenue':
                    if (isNaN(Number(rowDataValue))) {
                        errorMessage = `The Revenue, '${rowDataValue}' you provided is not a valid number.`;
                        return {
                            isValidated: false,
                            fileValidationMessage: `The file validation failed: ${errorMessage}.`,
                        };
                    }
                    break;
                case 'ip':
                    if (isIp(rowDataValue)) {
                        errorMessage = `The IP address, '${rowDataValue}' you provided is not valid.`;
                        return {
                            isValidated: false,
                            fileValidationMessage: `The file validation failed: ${errorMessage}.`,
                        };
                    }
                    break;
                case 'currency':
                    if (!validCurrencies.has(rowDataValue)) {
                        errorMessage = `The currency, '${rowDataValue}' you provided is not valid.`;
                        return {
                            isValidated: false,
                            fileValidationMessage: `The file validation failed: ${errorMessage}.`,
                        };
                    }
                    break;
                default:
                    break;
            }
        }
    }
    if (errorMessage.length > 0) {
        return {
            isValidated: false,
            fileValidationMessage: `The file validation failed: ${errorMessage}.`,
        };
    }

    return {
        isValidated: true,
        fileValidationMessage: '',
    };
};

type ValidateFileTypeResponse = {
    isValid: boolean;
    selectedFile: null;
    message: string;
};

export const validateFileType = (file: File, fileType: 'csv'): ValidateFileTypeResponse => {
    const fileExtension = (file?.name.split('.').pop() ?? '').toLowerCase();

    let result = {
        isValid: true,
        selectedFile: null,
        message: '',
    };

    if (fileExtension !== fileType) {
        result = {
            isValid: false,
            selectedFile: null,
            message: 'Sorry, this file is not in CSV format. Please upload a CSV file.',
        };
    }

    return result;
};
