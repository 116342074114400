// Import react dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import Core Dependencies
import Axios from 'axios';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import Request from '../../helpers/request';

// Import Redux Actions
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';
import { setModal } from '../../redux/actions/modal';
import { removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Import Components
import SimpleTable from '../../components/tables/components/simple-table';
import LoadingSpinner from '../../components/loading-spinner';
import { APISearchConsole } from '../modals/search-console-api';
import InputButton from '../../components/inputs/input-button';
import { ButtonThemes } from '../../enums/button-themes';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutPopupImportIntegrationAccounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            isImportLoading: false,
            importButtonDisabled: true,
            closeButtonDisabled: false,
            accounts: [],
            apiConsole: null,
            adwordResources: null,
        };

        this.onChildToggle = this.onChildToggle.bind(this);
        this.onParentToggle = this.onParentToggle.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onImportClick = this.onImportClick.bind(this);
        this.saveAdwords = this.saveAdwords.bind(this);
        this.saveAccounts = this.saveAccounts.bind(this);
    }

    request = new Request();

    prepareImportObjects(baseObjects) {
        baseObjects.forEach(obj => {
            obj['in_cubed'] = false;
            obj.active = false;

            if (obj.deleted === true) {
                obj['in_cubed'] = false;
                obj['previously_deleted'] = true;
            }

            if (obj.children === undefined) {
                obj.children = [];
            }

            // Set all the details of it's children
            obj.children.forEach(childObj => {
                childObj['in_cubed'] = true;

                if (childObj.deleted === true) {
                    childObj['in_cubed'] = false;
                    childObj['previously_deleted'] = true;
                }
            });
        });
        return baseObjects;
    }
    componentDidMount() {
        let requestUrls = [];
        if (this.props.technology === 'search-console') {
            let apiConsoleObj = new APISearchConsole(
                {
                    id: this.props.connectionId,
                    resource_uri: `/api/integrations/searchconsole-connection/${this.props.connectionId}/`,
                },
                this
            );
            apiConsoleObj.reimportdata(this.props.meta.apiDomain, this.props.account.token).then(objects => {
                this.setState({
                    accounts: this.prepareImportObjects(objects),
                    adwordsLookup: [],
                    isLoading: false,
                    importButtonDisabled: false,
                    apiConsole: apiConsoleObj,
                });
            });
            return;
        }
        if (this.props.technology === 'adwords-post-migration') {
            this.setState({
                adwordResources: {
                    adwordsAccountUrl: 'api-adwords-connection-account',
                    adwordsMccAccountUrl: 'api-adwords-connection-mcc-account',
                    adwordsAccountLookupUrl: 'api-adwords-connection-account-lookup',
                },
            });
        } else if (this.props.technology === 'adwords') {
            this.setState({
                adwordResources: {
                    adwordsAccountUrl: 'adwords-account',
                    adwordsMccAccountUrl: 'adwords-mcc-account',
                    adwordsAccountLookupUrl: 'adwords-account-lookup',
                },
            });
        }
        switch (this.props.technology) {
            case 'adwords':
                requestUrls = [
                    generateUrl('integrations', 'lite-adwords-mcc-account', [
                        { key: 'connection__id', value: this.props.connectionId },
                    ]),
                    `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/google-adwords/fetch-accounts/${this.props.connectionId}/`,
                    generateUrl('integrations', 'lite-uri-adwords-account-lookup'),
                ];
                break;
            case 'adwords-post-migration':
                requestUrls = [
                    generateUrl('integrations', 'lite-adwords-connection-mcc-account', [
                        { key: 'connection_detail__id', value: this.props.connectionId },
                    ]),
                    `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/google-adwords-connection/fetch-accounts/${this.props.connectionId}/`,
                    generateUrl('integrations', 'lite-uri-adwords-connection-account-lookup'),
                ];
                break;
            case 'facebook':
                requestUrls = [
                    generateUrl('integrations', 'facebook-account', [
                        { key: 'connection__id', value: this.props.connectionId },
                    ]),
                    `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/facebook/fetch-accounts/${this.props.connectionId}/`,
                ];
                break;
            case 'pinterest':
                requestUrls = [
                    generateUrl('integrations', 'lite-pinterest-account', [
                        { key: 'connection__id', value: this.props.connectionId },
                    ]),
                    `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/pinterest/fetch-accounts/${this.props.connectionId}/`,
                ];
                break;
            case 'bing':
                requestUrls = [
                    generateUrl('integrations', 'lite-bing-account', [
                        { key: 'connection__id', value: this.props.connectionId },
                    ]),
                    `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/bing/fetch-accounts/${this.props.connectionId}/`,
                ];
                break;
            case 'tiktok':
                requestUrls = [
                    generateUrl('integrations', 'tiktok-ad-accounts', [
                        { key: 'tiktok_connection__id', value: this.props.connectionId },
                    ]),
                    `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/tiktok/fetch-accounts/${this.props.connectionId}/`,
                ];
                break;
            default:
                break;
        }

        this.request
            .allUrl(requestUrls)
            .then(res => {
                res[1].data.objects.forEach(account => {
                    // Grab account if it's in Cubed
                    const accountInCubed = res[0].data.objects.filter(filteredAccount => {
                        if (this.props.technology === 'pinterest') {
                            return filteredAccount['pinterest_display_name'] === account['account_name'];
                        }

                        return filteredAccount['account_name'] === account['account_name'];
                    });
                    // If account is a parent loop through children
                    if (account['is_parent'] === true) {
                        account.children.forEach(childAccount => {
                            // If the parent is not in Cubed, the child can not be in Cubed
                            if (accountInCubed.length === 1) {
                                // Grab child account from parent in cubed
                                const childAccountInCubed = accountInCubed[0].children.filter(filteredAccount => {
                                    return filteredAccount['account_name'] === childAccount['account_name'];
                                });

                                // If child account is in cubed then set flag accordingly
                                if (childAccountInCubed.length === 1) {
                                    // Set flags and populate the data from the TP resource
                                    childAccount['in_cubed'] = true;
                                    childAccount.id = childAccountInCubed[0].id;
                                    childAccount.active = childAccountInCubed[0].active;
                                    childAccount.deleted = childAccountInCubed[0].deleted;

                                    // If child was in cubed but has the deleted flag, treat it like it wasn't in Cubed and set prev_deleted
                                    if (childAccount.deleted === true) {
                                        childAccount['in_cubed'] = false;
                                        childAccount['previously_deleted'] = true;
                                    }
                                } else {
                                    childAccount['in_cubed'] = false;
                                }
                            } else {
                                childAccount['in_cubed'] = false;
                            }
                        });
                    }

                    if (accountInCubed.length === 1) {
                        // As this row is already accounted for delete it from TP Array
                        res[0].data.objects.splice(res[0].data.objects.indexOf(accountInCubed[0]), 1);

                        // Set flags and populate the data from the TP resource
                        account.id = accountInCubed[0].id;
                        account.active = accountInCubed[0].active;
                        account.deleted = accountInCubed[0].deleted;
                        account['in_cubed'] = true;

                        // If parent was in cubed but has the deleted flag, treat it like it wasn't in Cubed and set prev_deleted
                        if (account.deleted === true) {
                            account['in_cubed'] = false;
                            account['previously_deleted'] = true;
                        }
                    } else {
                        account['in_cubed'] = false;
                    }

                    // Adding missing values from data coming in
                    if (account.children === undefined) {
                        account.children = [];
                    }
                    if (account.active === undefined) {
                        account.active = false;
                    }
                });

                // Go through all the remaining data that was from the cubed platform and set the "in_cubed" flag to true
                res[0].data.objects.forEach(account => {
                    // Adding missing values from data coming in
                    account['in_cubed'] = true;

                    if (account.deleted === true) {
                        account['in_cubed'] = false;
                        account['previously_deleted'] = true;
                    }

                    if (account.children === undefined) {
                        account.children = [];
                    }

                    // Set all the details of it's children
                    account.children.forEach(childAccount => {
                        childAccount['in_cubed'] = true;

                        if (childAccount.deleted === true) {
                            childAccount['in_cubed'] = false;
                            childAccount['previously_deleted'] = true;
                        }
                    });

                    if (account['account_name'] === undefined) {
                        account['account_name'] = account.name;
                    }
                });

                var accounts = res[0].data.objects.concat(res[1].data.objects);

                this.setState({
                    accounts: accounts,
                    adwordsLookup: res[2] !== undefined ? res[2].data.objects : [],
                    isLoading: false,
                    importButtonDisabled: false,
                });
            })
            .catch(err => {
                console.log('err', err);
                this.setState({
                    pageError: true,
                    isLoading: false,
                });
            });
    }

    capitalize(s) {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    onParentToggle(accountObject) {
        // If the account is in Cubed do not run the code to toggle things on or off
        if (accountObject['in_cubed'] === true) {
            return;
        }

        // Invert active as it was toggled and then set all of it's children to match that parent
        accountObject.active = !accountObject.active;

        if (accountObject.children) {
            accountObject.children.forEach(childAccount => {
                childAccount.active = accountObject.active;
            });
        }

        const accounts = this.state.accounts.map(account => {
            if ('mcc_id' in account && 'mcc_id' in accountObject && account.mcc_id === accountObject.mcc_id) {
                return accountObject;
            } else {
                return account;
            }
        });
        this.setState({
            accounts: accounts,
        });
    }

    onChildToggle(accountObject) {
        // loop through parent accounts, and their children
        // look for mcc_id (adwords only) - will be ignored by other APIs
        const accountsChanged = this.state.accounts.map(account => {
            if (account.customer_id === accountObject.mcc_id) {
                const accountChildren = account.children.map(child => {
                    if (child.customer_id === accountObject.customer_id) {
                        return {
                            ...child,
                            active: !accountObject.active,
                        };
                    }
                    return child;
                });
                let parentIsActive = accountChildren.some(childAccount => childAccount.active === true);
                return {
                    ...account,
                    active: parentIsActive,
                    children: accountChildren,
                };
            } else {
                return account;
            }
        });

        this.setState({
            accounts: accountsChanged,
        });
    }

    onNoParentAccountToggle(accountObject) {
        let updatedAccounts = this.state.accounts.map(account => {
            if (account.pk === accountObject.pk) {
                return {
                    ...account,
                    active: !accountObject.active,
                };
            }
            return account;
        });

        this.setState({
            accounts: updatedAccounts,
        });
    }

    onCloseClick() {
        this.props.removePopup();
    }

    // Due to parent child concept this can not use the generic save API functionality
    saveAdwords() {
        return new Promise((resolve, reject) => {
            const requests = [];

            this.state.accounts.forEach(account => {
                if (!account.canManageClients) {
                    var reqType = 'PUT';
                    delete account['children'];
                    delete account['in_cubed'];
                    account.currencyCode = account['currency_code'];
                    delete account['currency_code'];

                    if (account.pk === 0) {
                        // if no PK make a new row
                        delete account['pk'];
                        reqType = 'POST';
                    }
                    account.deleted = false;
                    account.companyName = '';

                    var url = generateUrlDetail(
                        'integrations',
                        this.state.adwordResources.adwordsAccountUrl,
                        account.id
                    );

                    requests.push(
                        Axios({
                            method: reqType,
                            url: url,
                            data: account,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                    return;
                }

                let hasActiveChildren = false;

                // Checking all the children of the parent to see if they have been toggled
                account.children.forEach(childAccount => {
                    if (!childAccount.in_cubed && childAccount.active === true) {
                        hasActiveChildren = true;
                        if (
                            childAccount['previously_deleted'] !== undefined &&
                            childAccount['previously_deleted'] === true
                        ) {
                            requests.push(
                                Axios({
                                    method: 'PATCH',
                                    url: generateUrlDetail(
                                        'integrations',
                                        this.state.adwordResources.adwordsAccountUrl,
                                        childAccount.id
                                    ),
                                    data: {
                                        active: 1,
                                        deleted: 0,
                                    },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        } else {
                            const trimmedChildAccount = { ...childAccount };

                            // Cleaning up object to to make sure it is what the Django models expect
                            trimmedChildAccount.currencyCode = trimmedChildAccount['currency_code'];

                            delete trimmedChildAccount['in_cubed'];
                            delete trimmedChildAccount['currency_code'];
                            delete trimmedChildAccount.children;
                            requests.push(
                                Axios({
                                    method: 'POST',
                                    url: generateUrl('integrations', this.state.adwordResources.adwordsAccountUrl),
                                    data: trimmedChildAccount,
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        }
                    }
                });
                if (account['in_cubed'] === false && (hasActiveChildren === true || account.active === true)) {
                    if (account['previously_deleted'] !== undefined && account['previously_deleted'] === true) {
                        requests.push(
                            Axios({
                                method: 'PATCH',
                                url: generateUrlDetail(
                                    'integrations',
                                    this.state.adwordResources.adwordsMccAccountUrl,
                                    account.id
                                ),
                                data: {
                                    active: 1,
                                    deleted: 0,
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    } else {
                        const trimmedAccount = { ...account };

                        // if a child account is active, set the parent to be
                        trimmedAccount.active = hasActiveChildren;

                        // Cleaning up object to to make sure it is what the Django models expect
                        trimmedAccount.currencyCode = trimmedAccount['currency_code'];

                        delete trimmedAccount['in_cubed'];
                        delete trimmedAccount['currency_code'];
                        delete trimmedAccount.children;

                        requests.push(
                            Axios({
                                method: 'POST',
                                url: generateUrl('integrations', this.state.adwordResources.adwordsMccAccountUrl),
                                data: trimmedAccount,
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    }
                }
            });

            // Run all the requests that will put data into mcc-account and account tables
            Axios.all(requests)
                .then(
                    Axios.spread((...res) => {
                        // Once data has been added to the mcc-account and account tables we need to add / update rows in the lookup table
                        const lookUpRequests = [];

                        this.state.accounts.forEach(account => {
                            if (!account.children) return;

                            account.children.forEach(childAccount => {
                                // If the child existed previously it means the lookup record still exists, just needs to be set to active.
                                if (childAccount['previously_deleted'] === true && childAccount.active === true) {
                                    // Grab the index from the lookup array
                                    const lookupRow = this.state.adwordsLookup.filter(row => {
                                        const mccId = parseInt(row['mcc_account'].split('/')[4]);
                                        const accountId = parseInt(row['account'].split('/')[4]);

                                        return mccId === account.id && accountId === childAccount.id;
                                    });

                                    lookUpRequests.push(
                                        Axios({
                                            method: 'PATCH',
                                            url: generateUrlDetail(
                                                'integrations',
                                                this.state.adwordResources.adwordsAccountLookupUrl,
                                                lookupRow[0].id
                                            ),
                                            data: {
                                                active: 1,
                                            },
                                            withCredentials: true,
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                        })
                                    );
                                }
                                // If it wasn't previously deleted then
                                else if (!childAccount.in_cubed && childAccount.active === true) {
                                    let parentId = account.id !== undefined ? account.id : null;
                                    let childId = null;

                                    res.forEach(response => {
                                        if (response.data['account_name'] === account['account_name']) {
                                            parentId = response.data.id;
                                        } else if (response.data['account_name'] === childAccount['account_name']) {
                                            childId = response.data.id;
                                        }
                                    });

                                    if (parentId === null || childId === null) {
                                        throw new Error('ParentId or ChildId could not be found');
                                    }

                                    lookUpRequests.push(
                                        Axios({
                                            method: 'POST',
                                            url: generateUrl(
                                                'integrations',
                                                this.state.adwordResources.adwordsAccountLookupUrl
                                            ),
                                            data: {
                                                mcc_account: generatePath(
                                                    'integrations',
                                                    this.state.adwordResources.adwordsMccAccountUrl,
                                                    parentId
                                                ),
                                                account: generatePath(
                                                    'integrations',
                                                    this.state.adwordResources.adwordsAccountUrl,
                                                    childId
                                                ),
                                            },
                                            withCredentials: true,
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                        })
                                    );
                                }
                            });
                        });

                        Axios.all(lookUpRequests)
                            .then(
                                Axios.spread((...lookupRes) => {
                                    resolve('All Good');
                                })
                            )
                            .catch(err => {
                                //console.log("axios all error:", error)
                                reject('no good');
                            });
                    })
                )
                .catch(err => {
                    //console.log("axios all first error:", err)
                    reject('no good');
                });
        });
    }

    saveAccounts() {
        return new Promise((resolve, reject) => {
            const requests = [];

            this.state.accounts.forEach(account => {
                if (account.active === false || account.active === 0 || account['in_cubed'] === true) {
                    return;
                }

                // All the API's have <tech>-account as this part of the path
                let accountPath = `${this.props.technology}-account`;

                if (this.props.technology === 'tiktok') {
                    accountPath = 'tiktok-ad-accounts';
                    account['name'] = account['account_name'];
                }

                if (account['previously_deleted'] !== undefined && account['previously_deleted'] === true) {
                    if (this.props.technology === 'pinterest') {
                        requests.push(
                            Axios({
                                method: 'PATCH',
                                url: generateUrlDetail('integrations', accountPath, account.id),
                                data: {
                                    active: 1,
                                    deleted: 0,
                                    user_consent: 1,
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    } else {
                        requests.push(
                            Axios({
                                method: 'PATCH',
                                url: generateUrlDetail('integrations', accountPath, account.id),
                                data: {
                                    active: 1,
                                    deleted: 0,
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    }
                } else {
                    // Remove unnecessary data
                    if (this.props.technology === 'pinterest') {
                        const tempAccount = { ...account };
                        delete tempAccount['in_cubed'];
                        delete tempAccount['previously_deleted'];
                        delete tempAccount.children;
                        delete tempAccount.connection;
                        const newTempAccount = {
                            pinterest_display_name: tempAccount['account_name'],
                            pinterest_account_id: tempAccount['account_number'],
                            currency: tempAccount['currency_code'],
                            country: tempAccount['time_zone'],
                            active: tempAccount['active'],
                            user_consent: tempAccount['active'],
                            deleted: !tempAccount['active'],
                            permissions: tempAccount['permissions'],
                            connection: generatePath(
                                'integrations',
                                `${this.props.technology}-connection`,
                                this.props.connectionId
                            ),
                        };
                        if (tempAccount['pk']) {
                            requests.push(
                                Axios({
                                    method: 'PATCH',
                                    url: generateUrlDetail('integrations', 'pinterest-account', tempAccount['pk']),
                                    data: newTempAccount,
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        } else {
                            requests.push(
                                Axios({
                                    method: 'POST',
                                    url: generateUrl('integrations', 'pinterest-account'),
                                    data: newTempAccount,
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        }
                    } else {
                        const tempAccount = { ...account };
                        delete tempAccount['in_cubed'];
                        delete tempAccount['previously_deleted'];
                        delete tempAccount.children;
                        delete tempAccount.connection;

                        tempAccount['connection'] = generatePath(
                            'integrations',
                            `${this.props.technology}-connection`,
                            this.props.connectionId
                        );

                        requests.push(
                            Axios({
                                method: 'POST',
                                url: generateUrl('integrations', accountPath),
                                data: tempAccount,
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    }
                }
            });

            Axios.all(requests)
                .then(
                    Axios.spread((...res) => {
                        resolve('All Good');
                    })
                )
                .catch(err => {
                    reject('no good');
                });
        });
    }

    onImportClick() {
        this.setState({
            isImportLoading: true,
        });

        if (this.props.technology === 'search-console') {
            this.state.apiConsole
                .handleImport()
                .then(() => {
                    if (this.props.onImportFinishOverride !== undefined) {
                        this.props.onImportFinishOverride();
                    }

                    this.props.removePopup();
                    this.props.addNotification({
                        copy: 'The accounts selected have been successfully imported into the Cubed Platform',
                        type: NotificationMessageType.Success,
                    });

                    if (this.props.onImportFinishOverride === undefined) {
                        this.props.setModal(`${this.capitalize(this.props.technology)}API`, {});
                    }

                    this.setState({
                        isImportLoading: false,
                    });
                })
                .catch(error => {
                    console.log('Error:', error);
                    this.props.addNotification({
                        copy: 'There was an issue trying to import these accounts into the Cubed Platform. Please try again later, or contact support@cubed.email',
                        type: NotificationMessageType.Error,
                    });

                    this.setState({
                        isImportLoading: false,
                    });
                });
            return;
        }
        if (this.props.technology === 'adwords-post-migration') {
            this.saveAdwords()
                .then(() => {
                    if (this.props.onImportFinishOverride !== undefined) {
                        this.props.onImportFinishOverride();
                    }

                    this.props.removePopup();
                    this.props.addNotification({
                        copy: 'The accounts selected have been successfully imported into the Cubed Platform',
                        type: NotificationMessageType.Success,
                    });

                    if (this.props.onImportFinishOverride === undefined) {
                        this.props.setModal(`${this.capitalize(this.props.technology)}API`, {});
                    }

                    this.setState({
                        isImportLoading: false,
                    });
                })
                .catch(error => {
                    console.log('Error:', error);
                    this.props.addNotification({
                        copy: 'There was an issue trying to import these accounts into the Cubed Platform. Please try again later, or contact support@cubed.email',
                        type: NotificationMessageType.Error,
                    });

                    this.setState({
                        isImportLoading: false,
                    });
                });
        } else {
            this.saveAccounts()
                .then(() => {
                    if (this.props.onImportFinishOverride !== undefined) {
                        this.props.onImportFinishOverride();
                    }

                    this.props.removePopup();
                    this.props.addNotification({
                        copy: 'The accounts selected have been successfully imported into the Cubed Platform',
                        type: NotificationMessageType.Success,
                    });

                    if (this.props.onImportFinishOverride === undefined) {
                        this.props.setModal(`${this.capitalize(this.props.technology)}API`, {});
                    }

                    this.setState({
                        isImportLoading: false,
                    });
                })
                .catch(error => {
                    this.props.addNotification({
                        copy: 'There was an issue trying to import these accounts into the Cubed Platform. Please try again later, or contact support@cubed.email',
                        type: NotificationMessageType.Error,
                    });

                    this.setState({
                        isImportLoading: false,
                    });
                });
        }
    }

    render() {
        if (this.state.isLoading === true) {
            return (
                <div className="popup__import-accounts">
                    <LoadingSpinner />
                    <InputButtonWrapper>
                        <InputButton
                            value="IMPORT AND SAVE"
                            onClick={this.onImportClick}
                            disabled={this.state.importButtonDisabled}
                        />
                        <InputButton
                            value="CLOSE"
                            buttonTheme={ButtonThemes.Secondary}
                            onClick={this.onCloseClick}
                            disabled={this.state.closeButtonDisabled}
                        />
                    </InputButtonWrapper>
                </div>
            );
        }

        const header = {
            columns: [
                {
                    title: 'Account Name',
                },
                {
                    title: 'Enabled',
                },
            ],
        };

        const rows = [];

        this.state.accounts.forEach(account => {
            rows.push({
                inCubed: account['in_cubed'],
                rowProperty: {
                    locked: account['in_cubed'] === true,
                },
                columns: [
                    {
                        copy: account['name'] || account['account_name'],
                    },
                    {
                        type: 'toggle',
                        toggleChecked: account['in_cubed'] === true ? false : account.active,
                        toggleDataName: account['account_name'],
                        toggleDisabled: account['in_cubed'],
                        toggleOnClick: account.onToggle
                            ? account.onToggle
                            : () => {
                                  this.onParentToggle(account);
                              },
                    },
                ],
            });

            if (account.children) {
                account.children.forEach(childAccount => {
                    rows.push({
                        inCubed: childAccount['in_cubed'],
                        rowProperty: {
                            locked: childAccount['in_cubed'] === true,
                            child: true,
                        },
                        columns: [
                            {
                                copy: childAccount['account_name'],
                            },
                            {
                                type: 'toggle',
                                toggleChecked: childAccount['in_cubed'] === true ? false : childAccount.active,
                                toggleDataName: childAccount['account_name'],
                                toggleDisabled: childAccount['in_cubed'],
                                toggleOnClick: account.onToggle
                                    ? account.onToggle
                                    : () => {
                                          this.onChildToggle(childAccount);
                                      },
                            },
                        ],
                    });
                });
            }
        });

        const filters = [
            {
                name: 'Not Imported',
                filter: row => {
                    return row.inCubed === false;
                },
            },
            {
                name: 'Active',
                filter: row => {
                    return row.columns[1].toggleChecked === true;
                },
            },
        ];

        const technology =
            this.props.technology === 'adwords-post-migration'
                ? 'Adwords'
                : this.props.technology === 'unified-google'
                ? 'Google'
                : this.props.technology === 'search-console'
                ? 'Google Search Console'
                : this.props.technology;

        return (
            <div className="popup__import-accounts">
                <p>{`A connection between the Cubed Platform and ${technology} has been successfully made. Please toggle the accounts below you would like to give the Cubed Platform access to.`}</p>

                <SimpleTable
                    rows={rows}
                    header={header}
                    enableSearch={true}
                    searchableColumns={[0]}
                    enableFilters={true}
                    filters={filters}
                    hasIcons={true}
                    errorMessageOverride="No accounts could be imported. If this is incorrect contact support@cubed.email."
                    isScrollable={true}
                />

                <InputButtonWrapper>
                    <InputButton
                        value="IMPORT AND SAVE"
                        onClick={this.onImportClick}
                        disabled={this.state.importButtonDisabled}
                        isLoading={this.state.isImportLoading}
                    />
                    <InputButton
                        value="CLOSE"
                        buttonStyle="secondary"
                        onClick={this.onCloseClick}
                        disabled={this.state.closeButtonDisabled}
                    />
                </InputButtonWrapper>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        request: state.request,
        meta: state.meta,
        account: state.account,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPopupImportIntegrationAccounts);
