/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Papa, { ParseResult } from 'papaparse';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { RootState } from '../../redux/reducers/core';

// Components
import ModalNavigation from '../../components/modal-navigation';
import InputButton from '../../components/inputs/input-button';
import ProgressBar from '../../components/progress-bar';
import WarningMessage from '../../components/warning-message';
import FormFileUpload from '../../components/form-fields/form-file-upload';

// Helpers
import LoadingSpinner from '../../components/loading-spinner';

// Resources
import { CONFIG_EVENT, OFFLINE_SALES_FILE_UPLOAD_CONFIG_RESOURCE } from '../../configurations/resources-config';

// Queries
import useCSVUploadResource from '../../react-query/hooks/use-csv-upload-resource';

// Helpers
import {
    validateFileType,
    validateOfflineSalesCSVFile,
    OfflineSalesData,
    OfflineSalesHeaders,
} from '../../helpers/validate-csv-file';
import WidgetBaseLoader from '../../section-dashboard/widgets/base/widget-base-loader';
import { generateUrl } from '../../helpers/request-builder';
import Axios from 'axios';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../react-query/types';

const StyledContainer = styled.div`
    margin-top: 20px;
    padding: 1px 20px 20px 20px;
    margin-right: auto;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

const StyledUploadOfflineSalesButton = styled.div`
    margin-top: 7px;
    margin-left: 10px;
`;
const StyledItemContainer = styled.div`
    padding: 5px;
    background: #f9f9f9;
`;

const StyledButtonWithMarginAndGap = styled.div`
    margin-top: 20px;
    display: flex;
    gap: 12px;
`;

const StyledValidationLoader = styled.div`
    display: flex;
    flex-gap: 10px;
    margin-top: 2px;
`;
const StyledValidationLabel = styled.p`
    justify-content: center;
    text-align: justify;
    align-content: center;
    margin-left: 5px;
    margin-bottom: 20px;
    font-weight: 500;
`;

const LayoutOfflineSalesModal = () => {
    const [fileValidationMessage, setFileValidationMessage] = useState('');
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [selectedFileIsValid, setSelectedFileIsValid] = useState(false);
    const [eventsData, setEventsData] = useState<Set<string>>(new Set());

    const accountId: string = useSelector((state: RootState) => state.account.id);
    const accountToken: string = useSelector((state: RootState) => state.account.token);

    const [validationInProgress, setValidationInProgress] = useState(false);
    const [uploadLog, setUploadLog] = useState<Blob | null>(null);

    const oneMBChunkSize = 1048576; //1024 * 1024;

    const {
        uploadIsLoading,
        uploadIsUploading,
        uploadComplete,
        uploadProgress,
        returningMessage,
        uploadError,
        resetStateFunction,
    } = useCSVUploadResource({
        fileUploadProgressResource: OFFLINE_SALES_FILE_UPLOAD_CONFIG_RESOURCE,
        formData,
    });

    // Queries & Mutations
    const eventQuery = useFetchResource({
        resource: CONFIG_EVENT,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(event => {
                return {
                    name: event.name,
                };
            });
        },
    });

    // Fetch Events
    useEffect(() => {
        if (eventQuery.data) {
            const eventNames: Set<string> = new Set();
            eventQuery.data.forEach(event => {
                eventNames.add(event.name);
            });
            setEventsData(eventNames);
        }
    }, [eventQuery.data]);

    useEffect(() => {
        if (Number(uploadProgress) >= 90 && uploadLog === null) {
            Axios({
                method: 'GET',
                url: generateUrl(`${accountToken}/download`, 'offline-sales-upload-logs'),
                responseType: 'blob',
                withCredentials: true,
            }).then(response => {
                if (response.data === 'No logs found') {
                    setUploadLog(null);
                    return;
                }
                const blob = new Blob([response.data], { type: 'application/zip' });
                setUploadLog(blob);
            });
        }
    }, [uploadProgress]);

    const onUploadOfflineSalesCSVClick = () => {
        const data = new FormData();
        data.append('upload_type', 'offline_sales');

        const blob = new Blob([selectedFile!], { type: selectedFile?.type });
        data.append('file', blob);
        data.append('account_id', accountId);

        setFormData(data);
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileValidationMessage('');
        setSelectedFileIsValid(false);
        setUploadButtonDisabled(true);
        setValidationInProgress(true);

        const file = event.target.files?.[0] as File;

        // Check the file extension
        const { isValid, selectedFile, message } = validateFileType(file, 'csv');

        if (!isValid) {
            setSelectedFileIsValid(isValid);
            setSelectedFile(selectedFile);
            setFileValidationMessage(message);
            return;
        }

        let headerChecked = false;
        let validationPassed = true;
        try {
            Papa.parse(file, {
                skipEmptyLines: true,
                header: true,
                chunkSize: oneMBChunkSize,
                chunk: (result, parser) => {
                    if (result.meta.fields === undefined) {
                        setSelectedFile(null);
                        setSelectedFileIsValid(false);
                        setFileValidationMessage(
                            "Sorry, this file doesn't appear to have data. Please check it and try again."
                        );
                        validationPassed = false;
                        parser.abort();
                        return;
                    }

                    if (!headerChecked) {
                        if (!validateCSVFileHeaders([...result.meta.fields])) {
                            setSelectedFile(null);
                            setSelectedFileIsValid(false);
                            validationPassed = false;
                            parser.abort();
                            return;
                        }
                        headerChecked = true;
                    }

                    const { isValidated, fileValidationMessage } = validateOfflineSalesCSVFile(
                        result.meta.fields as OfflineSalesHeaders,
                        result.data as OfflineSalesData[],
                        eventsData
                    );

                    setFileValidationMessage(fileValidationMessage);

                    if (!isValidated) {
                        setSelectedFileIsValid(false);
                        setSelectedFile(null);
                        validationPassed = false;
                        parser.abort();
                    }
                },
                complete: (results: ParseResult<Record<string, unknown>>, file: File) => {
                    // eslint-disable-line @typescript-eslint/no-unused-vars
                    setValidationInProgress(false);
                    if (validationPassed) {
                        setSelectedFileIsValid(true);
                        setUploadButtonDisabled(false);
                        setSelectedFile(file);
                    }
                },
            });
        } catch (error) {
            console.error(error);
            setSelectedFile(null);
            setSelectedFileIsValid(false);
            setFileValidationMessage('Sorry, there was a problem reading this file. Please check it and try again.');
        }
    };

    const validateCSVFileHeaders = (headerData: string[]) => {
        const sortedHeaders = [...headerData].sort();

        const preSortedFileHeaders = [
            'account_token',
            'cubed_event',
            'currency',
            'customer_id',
            'event_date_time',
            'event_item_sku',
            'ip',
            'page_url',
            'referrer',
            'revenue',
            'sync_id_1',
            'sync_id_2',
            'sync_id_3',
            'transaction_id',
        ];

        if (sortedHeaders.length !== preSortedFileHeaders.length) {
            setFileValidationMessage('File validation failed : The file does not contain correct number of columns.');
            return false;
        }
        for (let i = 0; i < sortedHeaders.length; i++) {
            if (sortedHeaders[i] !== preSortedFileHeaders[i]) {
                setFileValidationMessage('File validation failed : The file does not contain correct columns.');
                return false;
            }
        }
        return true;
    };

    const cancelOrFinishUpload = () => {
        setFileValidationMessage('');
        setUploadButtonDisabled(true);
        setSelectedFile(null);
        setSelectedFileIsValid(false);
        resetStateFunction();
    };

    const downloadLogs = () => {
        if (!uploadLog) {
            return;
        }

        const url = window.URL.createObjectURL(uploadLog);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'offline_sales_upload_logs.zip');
        document.body.appendChild(link);
        link.click();
    };

    const PageLayout = ({ children }: { children: React.ReactNode }) => {
        const dispatch = useDispatch();

        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: () => dispatch(removeModal()),
                disabled: false,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return (
            <div>
                <ModalNavigation buttons={modalNavigationButtons} />
                <h2>Upload offline sales</h2>
                <p>
                    Upload the offline sales here. It is important to use the defined CSV format - you can download it{' '}
                    <a href="/files/offline_sales.csv">here</a>.
                </p>
                <p>
                    More information about the fields can be found{' '}
                    <a href="https://tag.docs.withcubed.com/onboarding/offline-sales-upload/"> here.</a>
                </p>
                {children}
            </div>
        );
    };

    const ValidationLoader = () => {
        return (
            <StyledValidationLoader>
                <WidgetBaseLoader width={30} height={30} margin={0} />
                <StyledValidationLabel>Validating File</StyledValidationLabel>
            </StyledValidationLoader>
        );
    };

    const UploadOfflineSales = () => {
        return (
            <StyledItemContainer>
                <h3>Upload offline sales</h3>
                <StyledButtonContainer>
                    <FormFileUpload
                        onChange={onFileInputChange}
                        inputKeyValue={'add-offline-sales_file-upload'}
                        errorMessage={fileValidationMessage}
                        isValid={selectedFileIsValid}
                        uploadedFile={selectedFile?.name}
                    />
                    {validationInProgress ? (
                        <ValidationLoader />
                    ) : (
                        <>
                            <StyledUploadOfflineSalesButton>
                                <InputButton
                                    buttonTheme={ButtonThemes.Primary}
                                    value={'Upload'}
                                    onClick={onUploadOfflineSalesCSVClick}
                                    disabled={uploadButtonDisabled}
                                    hidden={!selectedFileIsValid}
                                />
                            </StyledUploadOfflineSalesButton>
                            <StyledUploadOfflineSalesButton>
                                <InputButton
                                    buttonTheme={ButtonThemes.Primary}
                                    value={'Cancel'}
                                    onClick={cancelOrFinishUpload}
                                    disabled={uploadButtonDisabled}
                                    hidden={!selectedFileIsValid}
                                />
                            </StyledUploadOfflineSalesButton>
                        </>
                    )}
                </StyledButtonContainer>
            </StyledItemContainer>
        );
    };

    const HomePage = () => {
        return <UploadOfflineSales />;
    };
    const UploadProgressBar = () => {
        return (
            <div className="file-upload-progress">
                {returningMessage && <p>{returningMessage}</p>}
                <p>{'Uploading offline sales.'}</p>
                <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                <StyledButtonWithMarginAndGap>
                    <InputButton
                        buttonTheme={ButtonThemes.Primary}
                        value={'Done'}
                        hidden={uploadProgress !== '100.00'}
                        onClick={cancelOrFinishUpload}
                    />
                    <InputButton
                        buttonTheme={ButtonThemes.Primary}
                        value={'Download Upload Logs'}
                        hidden={uploadProgress !== '100.00' || !uploadLog}
                        onClick={downloadLogs}
                    />
                </StyledButtonWithMarginAndGap>
            </div>
        );
    };
    const Error = () => {
        const message = returningMessage
            ? returningMessage
            : 'There was a server issue getting this page ready. Please try again later or contact support@cubed.com.';
        return <WarningMessage copy={message} />;
    };

    return (
        <PageLayout>
            <StyledContainer>
                {uploadIsLoading || eventQuery.isLoading ? (
                    <LoadingSpinner />
                ) : (uploadIsUploading || uploadComplete) && !uploadError ? (
                    <UploadProgressBar />
                ) : uploadError ? (
                    <Error />
                ) : (
                    <HomePage />
                )}
            </StyledContainer>
        </PageLayout>
    );
};

export default LayoutOfflineSalesModal;
