/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import WidgetTable from '../../section-dashboard/widgets/widget/widget-table';
import WidgetBigNumber from '../../section-dashboard/widgets/widget/widget-big-number';
import WidgetLineGraph from '../../section-dashboard/widgets/widget/widget-line-graph';
import WidgetMenuTable from '../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';

// Fields & Resources
import * as fields from '../../configurations/fields';
import * as resources from '../../configurations/resources';

import useResource from '../../section-dashboard/hooks/widget/use-resource';
import useResourceTable from '../../section-dashboard/hooks/widget/table/use-resource-table';
import useResourceBigNumber from '../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourceLineGraph from '../../section-dashboard/hooks/widget/line-graph/use-resource-line-graph';
import useResourceInterpolated from '../../section-dashboard/hooks/widget/line-graph/use-resource-line-graph-interpolated';
import useRequestTable from '../../section-dashboard/hooks/request/use-request-table';

// Helper
import { lastValue } from '../../section-dashboard/helpers/legend-order';

export const config = {
    title: 'Account Health Dashboard',
    description:
        'Explore general account health metrics including active propensity models, sync data and any tag errors.',
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        },
    },
    layouts: {
        xs: {
            sections: [
                {
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    'e'
                    'f'
                    'g'
                `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    grid: `
                    'a a'
                    'b b'
                    'c c'
                    'd d'
                    'e e'
                    'f f'
                    'g g'
                `,
                },
            ],
        },
        md: {
            sections: [
                {
                    grid: `
                    'a a a a'
                    'a a a a'
                    'b b b b'
                    'c c c c'
                    'c c c c'
                    'c c c c'
                    'd d e e'
                    'd d e e'
                    'd d e e'
                    'f f g g'
                    'f f g g'
                `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    grid: `
                    'a a a a b b'
                    'a a a a b b'
                    'c c c c c c'
                    'c c c c c c'
                    'd d d d e e'
                    'd d d d e e'
                    'f f f f g g'
                    'f f f f g g'
                `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    grid: `
                    'a a a a a a b b'
                    'a a a a a a b b'
                    'c c c c c c c c'
                    'c c c c c c c c'
                    'd d d d d d e e'
                    'd d d d d d e e'
                    'f f f f f f g g'
                    'f f f f f f g g'
                `,
                },
            ],
        },
    },
    components: () => {
        // Active Propensity Models
        const reportPropensityModelTableRequest = useRequestTable({
            groupBy: [],
            orderBy: [{ field: fields.MODEL_UPDATED_DATETIME, orderByDirection: 'asc' }],
            ignoreDate: true,
            fields: [fields.MODEL_NAME, fields.PRODUCT, fields.MODEL_CREATED_DATETIME, fields.MODEL_UPDATED_DATETIME],
        });

        const reportPropensityModelTableData = useResource({
            resource: resources.REPORT_PROPENSITY_MODEL,
            request: reportPropensityModelTableRequest,
        });

        const propensityModelTable = useResourceTable({
            resourceData: reportPropensityModelTableData,
        });

        // Overall Active Models Count
        const reportPropensityModelBigNumberData = useResource({
            resource: resources.REPORT_PROPENSITY_MODEL,
            request: {
                groupBy: [],
                orderBy: [{ field: fields.MODEL_UPDATED_DATETIME, orderByDirection: 'asc' }],
                ignoreDate: true,
                fields: [
                    fields.MODEL_NAME,
                    fields.PRODUCT,
                    fields.MODEL_CREATED_DATETIME,
                    fields.MODEL_UPDATED_DATETIME,
                ],
            },
        });

        const propensityModelCount = useResourceBigNumber({
            resourceData: reportPropensityModelBigNumberData,
            field: fields.COUNT,
            aggregation: 'sum',
        });

        // Propensity Model Performance
        const reportModelPerformanceTableRequest = useRequestTable({
            groupBy: [],
            orderBy: [{ field: fields.PROPENSITY_MODEL_NAME, orderByDirection: 'asc' }],
            excludeOrderBy: [fields.PRODUCT],
            fields: [
                fields.PROPENSITY_MODEL_NAME,
                fields.PRODUCT,
                fields.ACCURACY,
                fields.KAP,
                fields.TRUE_POSITIVE_RATE,
                fields.TRUE_NEGATIVE_RATE,
                fields.ROC_AUC,
                fields.PRECISION,
                fields.RECALL,
                fields.F1_SCORE,
            ],
            ignoreDate: true,
        });

        const reportModelPerformanceTableData = useResource({
            resource: resources.REPORT_PROPENSITY_MODEL_PERFORMANCE,
            request: reportModelPerformanceTableRequest,
        });

        const propensityModelPerformanceTable = useResourceTable({
            resourceData: reportModelPerformanceTableData,
        });

        // SYNCS
        const reportSyncs = useResource({
            resource: resources.REPORT_SYNCS,
            request: {
                groupBy: fields.CREATED_DATETIME,
                orderBy: [{ field: fields.CREATED_DATETIME, orderByDirection: 'asc' }],
            },
        });

        const syncsOverTimeGraph = useResourceLineGraph({
            resourceData: reportSyncs,
            dateDimension: fields.CREATED_DATETIME,
            seriesField: fields.SYNC_COUNT,
            yAxis: fields.SYNC_COUNT,
        });

        const syncCount = useResourceBigNumber({
            resourceData: reportSyncs,
            field: fields.SYNC_COUNT,
            aggregation: 'sum',
        });

        // VALIDATION MESSAGE
        const reportValidationMessageGraphDataSeries = useResource({
            resource: resources.REPORT_VALIDATION_MESSAGE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.DATE_DATETIME, fields.ERROR_MESSAGE],
                graphMetrics: [fields.COUNT],
            },
        });

        const reportValidationMessageDataSeriesInterpolated = useResourceInterpolated({
            resourceData: reportValidationMessageGraphDataSeries,
            dateDimension: fields.DATE_DATETIME,
            seriesField: fields.ERROR_MESSAGE,
            yAxis: fields.COUNT,
        });

        const errorCount = useResourceBigNumber({
            resourceData: reportValidationMessageGraphDataSeries,
            field: fields.COUNT,
            aggregation: 'sum',
        });

        const errorCountByErrorMessage = useResourceLineGraph({
            resourceData: reportValidationMessageDataSeriesInterpolated,
            dateDimension: fields.DATE_DATETIME,
            seriesField: fields.ERROR_MESSAGE,
            yAxis: fields.COUNT,
            legendOrder: lastValue(),
        });

        // Widget Links
        const links = {
            validationMessage: '/reports/health/error-reporting/',
        };

        return {
            a: (
                <WidgetTable
                    title={'Active Propensity Models'}
                    data={propensityModelTable}
                    menu={<WidgetMenuTable data={propensityModelTable} />}
                />
            ),
            b: <WidgetBigNumber title={'Overall Active Models Count'} data={propensityModelCount} />,
            c: (
                <WidgetTable
                    title={'Propensity Model Performance'}
                    data={propensityModelPerformanceTable}
                    menu={<WidgetMenuTable data={propensityModelPerformanceTable} />}
                />
            ),
            d: <WidgetLineGraph title={'Sync Count Over Time'} data={syncsOverTimeGraph} />,
            e: <WidgetBigNumber title={'Overall Sync Count'} data={syncCount} />,
            f: (
                <WidgetLineGraph
                    title={'Errors Over Time By Error Message'}
                    href={links.validationMessage}
                    data={errorCountByErrorMessage}
                />
            ),
            g: <WidgetBigNumber title={'Overall Error Count'} href={links.validationMessage} data={errorCount} />,
        };
    },
};
