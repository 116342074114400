// Check if it's a string
export function isString(stringValue) {
    return typeof stringValue === 'string';
}

// Check if the value contains only Alphanumerics
export function isAlphaNumericString(stringValue) {
    return isString(stringValue) && !/[^a-zA-Z\d\s:]/.test(stringValue);
}

// Check if the value only contains Alpha characters
export function isAlphaString(stringValue) {
    return isString(stringValue) && !/[^a-zA-Z\s:]/.test(stringValue);
}

// ChecK if the value is an email address
export function isEmail(value) {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
    return isString(value) && emailRegex.test(value);
}

export function isDomain(value) {
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
    return isString(value) && domainRegex.test(value);
}

// Check is value is a number, which includes floats and ints
export function isNumber(value) {
    return !isNaN(Number(value));
}

// Check if value is a float
export function isFloat(value) {
    return !isNaN(parseFloat(value));
}

export function isInteger(value) {
    return !isNaN(parseInt(value)) && value % 1 === 0;
}

// Check is value is price with 2 decimal points
export function isPrice(value) {
    return isNumber(value) && /^\d{1,}.\d{2}$|^\d{1,}$/.test(value);
}

// Check is value is a valid date
export function isDate(value) {
    return (
        isString(value) &&
        /^[0-3][0-9][/|-][0-1][0-9][/|-][0-9]{4}?|^[0-9]{4}[/|-][0-1][0-9][/|-][0-3][0-9]?/.test(value)
    );
}

// Check if value is a valid password
export function isPassword(value) {
    return isString(value) && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#^?&])[A-Za-z\d@$!%*#^?&]{8,}$/.test(value);
}

// Check if value is a valid IPV4 Address
export function isIp(value) {
    return (
        isString(value) &&
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)
    );
}

export function hasCapitalLetter(value) {
    return isString(value) && /[A-Z]/.test(value);
}

export function hasLowerCaseLetter(value) {
    return isString(value) && /[a-z]/.test(value);
}

export function hasNumber(value) {
    return isString(value) && /[0-9]/.test(value);
}

export function hasSpecialCharacter(value) {
    return isString(value) && /[@$!%*#^?&]/.test(value);
}

// Checks if password has valid letters, numbers and special characters
export function hasPasswordValidCharacters(value) {
    return isString(value) && /^([A-Z]|[a-z]|[0-9]|[@$!%*#^?&])+$/.test(value);
}

export function isUrl(value) {
    return (
        isString(value) &&
        /^([a-z]+\\:\/{2})?([\w-]+\.[\w-]+\.\w+)$|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
            value
        )
    );
}

export function isAlphaNumericSpecialCharacterString(value) {
    return isString(value) && /^[a-zA-Z0-9!@#$£&%*?()\\_\-`".+^,/"]*$/.test(value);
}

export const isValidCSVDate = dateString => {
    // Validates if a date string is in the format of YYYY-MM-DD HH:MM:SS
    const regexToMatch = /^\d{4}-[0-1][0-9]-[0-3][0-9] \d{2}:\d{2}:\d{2}$/;
    const dateValue = new Date(dateString);
    return regexToMatch.test(dateString) && dateValue instanceof Date && !isNaN(dateValue.getTime());
};
