import moment from 'moment';

export const getLastValidDay = (todayDate, dayOfTheWeek) => {
    // finds the last valid day where there is likely to be data in the database
    // based on data being pulled on the 1st and 14th of the month (gtrends)

    let todayDay = todayDate.date();
    dayOfTheWeek = dayOfTheWeek.charAt(0).toUpperCase() + dayOfTheWeek.slice(1);

    if (!moment.weekdays().includes(dayOfTheWeek)) {
        throw new Error('Day of the week must be a valid string');
    }

    let lastValidDay;
    if (todayDay < 14) {
        lastValidDay = moment().subtract(1, 'month').endOf('month');
        while (lastValidDay.format('dddd') !== dayOfTheWeek) {
            lastValidDay.subtract(1, 'day');
        }
    } else {
        lastValidDay = moment();
        while (lastValidDay.format('dddd') !== dayOfTheWeek || lastValidDay.date() >= 13) {
            lastValidDay.subtract(1, 'day');
        }
    }
    return lastValidDay.startOf('day');
};

export const getDateOfTheDay = (todayDate, dayOfTheWeek) => {
    // returns the date of a particular day specified by dayOfTheWeek for the previous week

    dayOfTheWeek = dayOfTheWeek.charAt(0).toUpperCase() + dayOfTheWeek.slice(1);

    if (!moment.weekdays().includes(dayOfTheWeek)) {
        throw new Error('Day of the week must be a valid string');
    }

    while (todayDate.format('dddd') !== dayOfTheWeek) {
        todayDate.subtract(1, 'day');
    }

    // This is the date of the Day in the last week
    return todayDate;
};

export const firstThreeSaturdaysThisMonth = () => {
    let firstDayOfMonth = moment({ year: moment().year(), month: moment().month(), day: 1 });

    // Find the first Saturday
    let firstSaturday = firstDayOfMonth.day(6);
    if (firstSaturday.date() > 7) {
        firstSaturday = firstSaturday.add(1, 'week');
    }
    let secondSaturday = firstSaturday.clone().add(1, 'week');

    // Get the third Saturday by adding two weeks to the first Saturday
    let thirdSaturday = firstSaturday.clone().add(2, 'weeks');

    return [firstSaturday, secondSaturday, thirdSaturday];
};
